/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AvailableType } from '../model/available-type';
// @ts-ignore
import { GetFundraiserStatisticsResultDto } from '../model/get-fundraiser-statistics-result-dto';
// @ts-ignore
import { GetSpenderStatisticsResultDto } from '../model/get-spender-statistics-result-dto';
// @ts-ignore
import { GetStatisticsDateBaseCurrencyCoinsQueryDto } from '../model/get-statistics-date-base-currency-coins-query-dto';
// @ts-ignore
import { GetStatisticsDetailsExportResultDto } from '../model/get-statistics-details-export-result-dto';
// @ts-ignore
import { GetStatisticsDetailsQueryDto } from '../model/get-statistics-details-query-dto';
// @ts-ignore
import { GetStatisticsDetailsResultDto } from '../model/get-statistics-details-result-dto';
// @ts-ignore
import { GetStatisticsFinancialQueryTypeAvailableDto } from '../model/get-statistics-financial-query-type-available-dto';
// @ts-ignore
import { GetStatisticsFinancialQueryTypeTransactionsDto } from '../model/get-statistics-financial-query-type-transactions-dto';
// @ts-ignore
import { GetStatisticsFinancialTotalResultDto } from '../model/get-statistics-financial-total-result-dto';
// @ts-ignore
import { GetStatisticsStoreResultDto } from '../model/get-statistics-store-result-dto';
// @ts-ignore
import { GetStatisticsSummaryResultDto } from '../model/get-statistics-summary-result-dto';
// @ts-ignore
import { GetStatisticsUsageTotalResultDto } from '../model/get-statistics-usage-total-result-dto';
// @ts-ignore
import { GetTransactionStatisticsDetailsQueryDto } from '../model/get-transaction-statistics-details-query-dto';
// @ts-ignore
import { StatisticsQueryPeriod } from '../model/statistics-query-period';
// @ts-ignore
import { StatisticsQueryViewType } from '../model/statistics-query-view-type';
// @ts-ignore
import { StatisticsUsageType } from '../model/statistics-usage-type';
// @ts-ignore
import { TransactionType } from '../model/transaction-type';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

    protected basePath = 'http://127.0.0.1:3333';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * 
     * @param getStatisticsFinancialQueryTypeAvailableDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportFinancialStatisticsAvailable(getStatisticsFinancialQueryTypeAvailableDto: GetStatisticsFinancialQueryTypeAvailableDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsExportResultDto>;
    public exportFinancialStatisticsAvailable(getStatisticsFinancialQueryTypeAvailableDto: GetStatisticsFinancialQueryTypeAvailableDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsExportResultDto>>;
    public exportFinancialStatisticsAvailable(getStatisticsFinancialQueryTypeAvailableDto: GetStatisticsFinancialQueryTypeAvailableDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsExportResultDto>>;
    public exportFinancialStatisticsAvailable(getStatisticsFinancialQueryTypeAvailableDto: GetStatisticsFinancialQueryTypeAvailableDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (getStatisticsFinancialQueryTypeAvailableDto === null || getStatisticsFinancialQueryTypeAvailableDto === undefined) {
            throw new Error('Required parameter getStatisticsFinancialQueryTypeAvailableDto was null or undefined when calling exportFinancialStatisticsAvailable.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/financial/available/exports`;
        return this.httpClient.request<GetStatisticsDetailsExportResultDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getStatisticsFinancialQueryTypeAvailableDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param getStatisticsFinancialQueryTypeTransactionsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportFinancialStatisticsTransactions(getStatisticsFinancialQueryTypeTransactionsDto: GetStatisticsFinancialQueryTypeTransactionsDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsExportResultDto>;
    public exportFinancialStatisticsTransactions(getStatisticsFinancialQueryTypeTransactionsDto: GetStatisticsFinancialQueryTypeTransactionsDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsExportResultDto>>;
    public exportFinancialStatisticsTransactions(getStatisticsFinancialQueryTypeTransactionsDto: GetStatisticsFinancialQueryTypeTransactionsDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsExportResultDto>>;
    public exportFinancialStatisticsTransactions(getStatisticsFinancialQueryTypeTransactionsDto: GetStatisticsFinancialQueryTypeTransactionsDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (getStatisticsFinancialQueryTypeTransactionsDto === null || getStatisticsFinancialQueryTypeTransactionsDto === undefined) {
            throw new Error('Required parameter getStatisticsFinancialQueryTypeTransactionsDto was null or undefined when calling exportFinancialStatisticsTransactions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/financial/transactions/exports`;
        return this.httpClient.request<GetStatisticsDetailsExportResultDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getStatisticsFinancialQueryTypeTransactionsDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param getStatisticsDateBaseCurrencyCoinsQueryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportFundraiserStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsExportResultDto>;
    public exportFundraiserStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsExportResultDto>>;
    public exportFundraiserStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsExportResultDto>>;
    public exportFundraiserStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (getStatisticsDateBaseCurrencyCoinsQueryDto === null || getStatisticsDateBaseCurrencyCoinsQueryDto === undefined) {
            throw new Error('Required parameter getStatisticsDateBaseCurrencyCoinsQueryDto was null or undefined when calling exportFundraiserStatistics.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/fundraiser/exports`;
        return this.httpClient.request<GetStatisticsDetailsExportResultDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getStatisticsDateBaseCurrencyCoinsQueryDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param getStatisticsDateBaseCurrencyCoinsQueryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportSpenderStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsExportResultDto>;
    public exportSpenderStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsExportResultDto>>;
    public exportSpenderStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsExportResultDto>>;
    public exportSpenderStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (getStatisticsDateBaseCurrencyCoinsQueryDto === null || getStatisticsDateBaseCurrencyCoinsQueryDto === undefined) {
            throw new Error('Required parameter getStatisticsDateBaseCurrencyCoinsQueryDto was null or undefined when calling exportSpenderStatistics.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/spender/exports`;
        return this.httpClient.request<GetStatisticsDetailsExportResultDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getStatisticsDateBaseCurrencyCoinsQueryDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param getStatisticsDateBaseCurrencyCoinsQueryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportStoreStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsExportResultDto>;
    public exportStoreStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsExportResultDto>>;
    public exportStoreStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsExportResultDto>>;
    public exportStoreStatistics(getStatisticsDateBaseCurrencyCoinsQueryDto: GetStatisticsDateBaseCurrencyCoinsQueryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (getStatisticsDateBaseCurrencyCoinsQueryDto === null || getStatisticsDateBaseCurrencyCoinsQueryDto === undefined) {
            throw new Error('Required parameter getStatisticsDateBaseCurrencyCoinsQueryDto was null or undefined when calling exportStoreStatistics.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/store/exports`;
        return this.httpClient.request<GetStatisticsDetailsExportResultDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getStatisticsDateBaseCurrencyCoinsQueryDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param type 
     * @param getStatisticsDetailsQueryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportUsageStatisticsByType(type: string, getStatisticsDetailsQueryDto: GetStatisticsDetailsQueryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsExportResultDto>;
    public exportUsageStatisticsByType(type: string, getStatisticsDetailsQueryDto: GetStatisticsDetailsQueryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsExportResultDto>>;
    public exportUsageStatisticsByType(type: string, getStatisticsDetailsQueryDto: GetStatisticsDetailsQueryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsExportResultDto>>;
    public exportUsageStatisticsByType(type: string, getStatisticsDetailsQueryDto: GetStatisticsDetailsQueryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling exportUsageStatisticsByType.');
        }
        if (getStatisticsDetailsQueryDto === null || getStatisticsDetailsQueryDto === undefined) {
            throw new Error('Required parameter getStatisticsDetailsQueryDto was null or undefined when calling exportUsageStatisticsByType.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/usage/${this.configuration.encodeParam({name: "type", value: type, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/exports`;
        return this.httpClient.request<GetStatisticsDetailsExportResultDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getStatisticsDetailsQueryDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param getTransactionStatisticsDetailsQueryDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportUsageStatisticsTransactions(getTransactionStatisticsDetailsQueryDto: GetTransactionStatisticsDetailsQueryDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsExportResultDto>;
    public exportUsageStatisticsTransactions(getTransactionStatisticsDetailsQueryDto: GetTransactionStatisticsDetailsQueryDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsExportResultDto>>;
    public exportUsageStatisticsTransactions(getTransactionStatisticsDetailsQueryDto: GetTransactionStatisticsDetailsQueryDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsExportResultDto>>;
    public exportUsageStatisticsTransactions(getTransactionStatisticsDetailsQueryDto: GetTransactionStatisticsDetailsQueryDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (getTransactionStatisticsDetailsQueryDto === null || getTransactionStatisticsDetailsQueryDto === undefined) {
            throw new Error('Required parameter getTransactionStatisticsDetailsQueryDto was null or undefined when calling exportUsageStatisticsTransactions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/usage/transactions/exports`;
        return this.httpClient.request<GetStatisticsDetailsExportResultDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getTransactionStatisticsDetailsQueryDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param viewType 
     * @param period 
     * @param availableType 
     * @param baseCurrencyId 
     * @param timezone 
     * @param from 
     * @param until 
     * @param coinIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFinancialStatisticsAvailable(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, availableType: AvailableType, baseCurrencyId: string, timezone: string, from: string, until: string, coinIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsResultDto>;
    public getFinancialStatisticsAvailable(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, availableType: AvailableType, baseCurrencyId: string, timezone: string, from: string, until: string, coinIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsResultDto>>;
    public getFinancialStatisticsAvailable(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, availableType: AvailableType, baseCurrencyId: string, timezone: string, from: string, until: string, coinIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsResultDto>>;
    public getFinancialStatisticsAvailable(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, availableType: AvailableType, baseCurrencyId: string, timezone: string, from: string, until: string, coinIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (viewType === null || viewType === undefined) {
            throw new Error('Required parameter viewType was null or undefined when calling getFinancialStatisticsAvailable.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling getFinancialStatisticsAvailable.');
        }
        if (availableType === null || availableType === undefined) {
            throw new Error('Required parameter availableType was null or undefined when calling getFinancialStatisticsAvailable.');
        }
        if (baseCurrencyId === null || baseCurrencyId === undefined) {
            throw new Error('Required parameter baseCurrencyId was null or undefined when calling getFinancialStatisticsAvailable.');
        }
        if (timezone === null || timezone === undefined) {
            throw new Error('Required parameter timezone was null or undefined when calling getFinancialStatisticsAvailable.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getFinancialStatisticsAvailable.');
        }
        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling getFinancialStatisticsAvailable.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (viewType !== undefined && viewType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>viewType, 'viewType');
        }
        if (period !== undefined && period !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>period, 'period');
        }
        if (availableType !== undefined && availableType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>availableType, 'availableType');
        }
        if (coinIds) {
            coinIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'coinIds');
            })
        }
        if (baseCurrencyId !== undefined && baseCurrencyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baseCurrencyId, 'baseCurrencyId');
        }
        if (timezone !== undefined && timezone !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timezone, 'timezone');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/financial/available`;
        return this.httpClient.request<GetStatisticsDetailsResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param baseCurrencyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFinancialStatisticsTotal(baseCurrencyId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsFinancialTotalResultDto>;
    public getFinancialStatisticsTotal(baseCurrencyId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsFinancialTotalResultDto>>;
    public getFinancialStatisticsTotal(baseCurrencyId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsFinancialTotalResultDto>>;
    public getFinancialStatisticsTotal(baseCurrencyId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (baseCurrencyId === null || baseCurrencyId === undefined) {
            throw new Error('Required parameter baseCurrencyId was null or undefined when calling getFinancialStatisticsTotal.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (baseCurrencyId !== undefined && baseCurrencyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baseCurrencyId, 'baseCurrencyId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/financial/total`;
        return this.httpClient.request<GetStatisticsFinancialTotalResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param viewType 
     * @param period 
     * @param baseCurrencyId 
     * @param timezone 
     * @param from 
     * @param until 
     * @param transactionTypes 
     * @param coinIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFinancialStatisticsTransactions(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, baseCurrencyId: string, timezone: string, from: string, until: string, transactionTypes?: Array<TransactionType>, coinIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsResultDto>;
    public getFinancialStatisticsTransactions(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, baseCurrencyId: string, timezone: string, from: string, until: string, transactionTypes?: Array<TransactionType>, coinIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsResultDto>>;
    public getFinancialStatisticsTransactions(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, baseCurrencyId: string, timezone: string, from: string, until: string, transactionTypes?: Array<TransactionType>, coinIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsResultDto>>;
    public getFinancialStatisticsTransactions(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, baseCurrencyId: string, timezone: string, from: string, until: string, transactionTypes?: Array<TransactionType>, coinIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (viewType === null || viewType === undefined) {
            throw new Error('Required parameter viewType was null or undefined when calling getFinancialStatisticsTransactions.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling getFinancialStatisticsTransactions.');
        }
        if (baseCurrencyId === null || baseCurrencyId === undefined) {
            throw new Error('Required parameter baseCurrencyId was null or undefined when calling getFinancialStatisticsTransactions.');
        }
        if (timezone === null || timezone === undefined) {
            throw new Error('Required parameter timezone was null or undefined when calling getFinancialStatisticsTransactions.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getFinancialStatisticsTransactions.');
        }
        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling getFinancialStatisticsTransactions.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (viewType !== undefined && viewType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>viewType, 'viewType');
        }
        if (period !== undefined && period !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>period, 'period');
        }
        if (transactionTypes) {
            transactionTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'transactionTypes');
            })
        }
        if (coinIds) {
            coinIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'coinIds');
            })
        }
        if (baseCurrencyId !== undefined && baseCurrencyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baseCurrencyId, 'baseCurrencyId');
        }
        if (timezone !== undefined && timezone !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timezone, 'timezone');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/financial/transactions`;
        return this.httpClient.request<GetStatisticsDetailsResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param baseCurrencyId 
     * @param from 
     * @param until 
     * @param coinIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFundraiserStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetFundraiserStatisticsResultDto>;
    public getFundraiserStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetFundraiserStatisticsResultDto>>;
    public getFundraiserStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetFundraiserStatisticsResultDto>>;
    public getFundraiserStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (baseCurrencyId === null || baseCurrencyId === undefined) {
            throw new Error('Required parameter baseCurrencyId was null or undefined when calling getFundraiserStatistics.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getFundraiserStatistics.');
        }
        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling getFundraiserStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (baseCurrencyId !== undefined && baseCurrencyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baseCurrencyId, 'baseCurrencyId');
        }
        if (coinIds) {
            coinIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'coinIds');
            })
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/fundraiser`;
        return this.httpClient.request<GetFundraiserStatisticsResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param baseCurrencyId 
     * @param from 
     * @param until 
     * @param coinIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSpenderStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetSpenderStatisticsResultDto>;
    public getSpenderStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetSpenderStatisticsResultDto>>;
    public getSpenderStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetSpenderStatisticsResultDto>>;
    public getSpenderStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (baseCurrencyId === null || baseCurrencyId === undefined) {
            throw new Error('Required parameter baseCurrencyId was null or undefined when calling getSpenderStatistics.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getSpenderStatistics.');
        }
        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling getSpenderStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (baseCurrencyId !== undefined && baseCurrencyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baseCurrencyId, 'baseCurrencyId');
        }
        if (coinIds) {
            coinIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'coinIds');
            })
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/spender`;
        return this.httpClient.request<GetSpenderStatisticsResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param baseCurrencyId 
     * @param from 
     * @param until 
     * @param coinIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStoreStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsStoreResultDto>;
    public getStoreStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsStoreResultDto>>;
    public getStoreStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsStoreResultDto>>;
    public getStoreStatistics(baseCurrencyId: string, from: string, until: string, coinIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (baseCurrencyId === null || baseCurrencyId === undefined) {
            throw new Error('Required parameter baseCurrencyId was null or undefined when calling getStoreStatistics.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getStoreStatistics.');
        }
        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling getStoreStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (baseCurrencyId !== undefined && baseCurrencyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baseCurrencyId, 'baseCurrencyId');
        }
        if (coinIds) {
            coinIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'coinIds');
            })
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/store`;
        return this.httpClient.request<GetStatisticsStoreResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param baseCurrencyId 
     * @param from 
     * @param until 
     * @param coinIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSummaryStatistics(baseCurrencyId: string, from?: string, until?: string, coinIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsSummaryResultDto>;
    public getSummaryStatistics(baseCurrencyId: string, from?: string, until?: string, coinIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsSummaryResultDto>>;
    public getSummaryStatistics(baseCurrencyId: string, from?: string, until?: string, coinIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsSummaryResultDto>>;
    public getSummaryStatistics(baseCurrencyId: string, from?: string, until?: string, coinIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (baseCurrencyId === null || baseCurrencyId === undefined) {
            throw new Error('Required parameter baseCurrencyId was null or undefined when calling getSummaryStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (baseCurrencyId !== undefined && baseCurrencyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baseCurrencyId, 'baseCurrencyId');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }
        if (coinIds) {
            coinIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'coinIds');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/summary`;
        return this.httpClient.request<GetStatisticsSummaryResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param type 
     * @param viewType 
     * @param period 
     * @param timezone 
     * @param from 
     * @param until 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsageStatisticsByType(type: StatisticsUsageType, viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, timezone: string, from: string, until: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsResultDto>;
    public getUsageStatisticsByType(type: StatisticsUsageType, viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, timezone: string, from: string, until: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsResultDto>>;
    public getUsageStatisticsByType(type: StatisticsUsageType, viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, timezone: string, from: string, until: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsResultDto>>;
    public getUsageStatisticsByType(type: StatisticsUsageType, viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, timezone: string, from: string, until: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getUsageStatisticsByType.');
        }
        if (viewType === null || viewType === undefined) {
            throw new Error('Required parameter viewType was null or undefined when calling getUsageStatisticsByType.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling getUsageStatisticsByType.');
        }
        if (timezone === null || timezone === undefined) {
            throw new Error('Required parameter timezone was null or undefined when calling getUsageStatisticsByType.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getUsageStatisticsByType.');
        }
        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling getUsageStatisticsByType.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (viewType !== undefined && viewType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>viewType, 'viewType');
        }
        if (period !== undefined && period !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>period, 'period');
        }
        if (timezone !== undefined && timezone !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timezone, 'timezone');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/usage/${this.configuration.encodeParam({name: "type", value: type, in: "path", style: "simple", explode: false, dataType: "StatisticsUsageType", dataFormat: undefined})}`;
        return this.httpClient.request<GetStatisticsDetailsResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsageStatisticsTotal(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsUsageTotalResultDto>;
    public getUsageStatisticsTotal(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsUsageTotalResultDto>>;
    public getUsageStatisticsTotal(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsUsageTotalResultDto>>;
    public getUsageStatisticsTotal(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/usage/total`;
        return this.httpClient.request<GetStatisticsUsageTotalResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param viewType 
     * @param period 
     * @param timezone 
     * @param from 
     * @param until 
     * @param transactionTypes 
     * @param coinIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsageStatisticsTransactions(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, timezone: string, from: string, until: string, transactionTypes?: Array<TransactionType>, coinIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetStatisticsDetailsResultDto>;
    public getUsageStatisticsTransactions(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, timezone: string, from: string, until: string, transactionTypes?: Array<TransactionType>, coinIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetStatisticsDetailsResultDto>>;
    public getUsageStatisticsTransactions(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, timezone: string, from: string, until: string, transactionTypes?: Array<TransactionType>, coinIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetStatisticsDetailsResultDto>>;
    public getUsageStatisticsTransactions(viewType: StatisticsQueryViewType, period: StatisticsQueryPeriod, timezone: string, from: string, until: string, transactionTypes?: Array<TransactionType>, coinIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (viewType === null || viewType === undefined) {
            throw new Error('Required parameter viewType was null or undefined when calling getUsageStatisticsTransactions.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling getUsageStatisticsTransactions.');
        }
        if (timezone === null || timezone === undefined) {
            throw new Error('Required parameter timezone was null or undefined when calling getUsageStatisticsTransactions.');
        }
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getUsageStatisticsTransactions.');
        }
        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling getUsageStatisticsTransactions.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (viewType !== undefined && viewType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>viewType, 'viewType');
        }
        if (period !== undefined && period !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>period, 'period');
        }
        if (transactionTypes) {
            transactionTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'transactionTypes');
            })
        }
        if (coinIds) {
            coinIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'coinIds');
            })
        }
        if (timezone !== undefined && timezone !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timezone, 'timezone');
        }
        if (from !== undefined && from !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>from, 'from');
        }
        if (until !== undefined && until !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>until, 'until');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        // authentication (x-api-key) required
        localVarCredential = this.configuration.lookupCredential('x-api-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('x-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/statistics/usage/transactions`;
        return this.httpClient.request<GetStatisticsDetailsResultDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
