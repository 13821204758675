/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyBusiness {
    NOT_FOUND = 'business-not-found',
    STILL_HAS_STORES = 'business-still-has-stores',
    VAT_ALREADY_IN_USE = 'business-vat-already-in-use',
    NO_PERMISSION = 'business-no-permission'
}

