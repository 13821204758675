/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyBankAccount {
    BANK_ACCOUNT_ACCOUNT_NOT_FOUND = 'bank-account-account-not-found'
}

