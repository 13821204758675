/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum TransactionType {
    DEPOSIT_TO_ECONOMY = 'DEPOSIT_TO_ECONOMY',
    DEPOSIT_TO_ECONOMY_SPONSORED = 'DEPOSIT_TO_ECONOMY_SPONSORED',
    COMMISSION = 'COMMISSION',
    REFUND = 'REFUND',
    TRANSFER = 'TRANSFER',
    EARN = 'EARN',
    SPEND = 'SPEND',
    DEBET = 'DEBET',
    SPONSOR = 'SPONSOR',
    DEPOSIT_TO_USER = 'DEPOSIT_TO_USER',
    EXPIRED = 'EXPIRED',
    DEPOSIT_TO_VOUCHER = 'DEPOSIT_TO_VOUCHER'
}

