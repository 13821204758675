/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyVoucherExportTemplates {
    TEMPLATE_NOT_FOUND = 'voucher-export-template-not-found',
    INVALID_TEMPLATE = 'voucher-export-invalid-template'
}

