/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum AppWebSocketUserUpdatedDtoType {
    WEB_SOCKET_USER_UPDATED = '[WebSocket] User Updated'
}

