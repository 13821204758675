/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum IdpOauth2LoginClaim {
    SUB = 'sub',
    EMAIL = 'email',
    EMAIL_VERIFIED = 'email_verified',
    NAME = 'name',
    GIVEN_NAME = 'given_name',
    FAMILY_NAME = 'family_name'
}

