/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StoreCoinStatusUpdatedPushDataDto { 
    action: StoreCoinStatusUpdatedPushDataDtoAction;
    economyId: string;
    storeId: string;
    businessId: string;
}
export enum StoreCoinStatusUpdatedPushDataDtoAction {
    STORE_COIN_ACCEPTED = 'storeCoinAccepted',
    STORE_COIN_REJECTED = 'storeCoinRejected'
};



