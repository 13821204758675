/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SqlBackupRunStatus {
    SQL_BACKUP_RUN_STATUS_UNSPECIFIED = 'SQL_BACKUP_RUN_STATUS_UNSPECIFIED',
    ENQUEUED = 'ENQUEUED',
    OVERDUE = 'OVERDUE',
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
    SUCCESSFUL = 'SUCCESSFUL',
    SKIPPED = 'SKIPPED',
    DELETION_PENDING = 'DELETION_PENDING',
    DELETION_FAILED = 'DELETION_FAILED',
    DELETED = 'DELETED'
}

