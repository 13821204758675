/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PointDto { 
    type: PointDtoType;
    /**
     * longitude, latitude
     */
    coordinates: Array<number>;
}
export enum PointDtoType {
    POINT = 'Point'
};



