/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OpenInvitationsPushDataDto { 
    action: OpenInvitationsPushDataDtoAction;
}
export enum OpenInvitationsPushDataDtoAction {
    OPEN_INVITATIONS = 'openInvitations'
};



