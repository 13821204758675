/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum EconomyStatus {
    CREATED = 'Created',
    ACTIVE = 'Active',
    CLOSED = 'Closed'
}

