/**
 * Otzy API Documentation
 *  
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyUsers {
    INVALID_PINCODE = 'user-invalid-pincode',
    NOT_FOUND = 'user-not-found',
    STILL_HAS_GROUPS = 'user-still-has-groups'
}

